import React from "react";

import { graphql } from 'gatsby'
import BackgroundImage from 'gatsby-background-image';
import Img from 'gatsby-image';

import Header from '../../components/header';
import Footer from '../../components/footer';
import Meta from '../../components/meta';

export const query = graphql`
  query {
    gemstoneJumbotron: file(relativePath: { regex: "/^raw-BC-nephrite-jade.jpg$/" }) {
      childImageSharp {
        fluid(quality:90) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    },
    gemTool: file(relativePath: { regex: "/^gem-tool.png$/" }) {
      childImageSharp {
        fluid(quality:90) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    },
    gemScenic: file(relativePath: { regex: "/^gem-scenic.png$/" }) {
      childImageSharp {
        fluid(quality:90) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    },
    jadeGradeLight: file(relativePath: { regex: "/^jade-grade-light.png$/" }) {
      childImageSharp {
        fluid(quality:90) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    },
    jadeClamp: file(relativePath: { regex: "/^jade-clamp.png$/" }) {
      childImageSharp {
        fluid(quality:90) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    },
    jadeCut: file(relativePath: { regex: "/^jade-cutting.png$/" }) {
      childImageSharp {
        fluid(quality:90) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    
  }
`

class Gemstones extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tab: 'grading',
      under576: false
    }

    this.handleCategoryClick = this.handleCategoryClick.bind(this);
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }
  
  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }
  
  updateWindowDimensions() {
    if (window.innerWidth < 576 && !this.state.under576) {
      this.setState({under576: true});
    } else if (window.innerWidth > 576 && this.state.under576) {
      this.setState({under576: false});
    }
  }

  handleCategoryClick = (category) => {
    this.setState({
      tab: category
    })
  };

  render() {
    return(
      <div className="vh-100">
        <Meta title="Gemstone" />
        <Header darkFont={false}/>

        <BackgroundImage className="p-5 container-fluid black-background" fluid={this.props.data.gemstoneJumbotron.childImageSharp.fluid} >
          <div className="row justify-content-center my-5">
            <div className="col-12 text-center mt-5 pt-0 pt-md-5">
              <p className="sans-serif text-white mt-5">Products</p>              
              <h1 className="text-white serif display-4 d-inline-block">GEMSTONE</h1>
              <hr className="col-1 horizontal-divider my-0"></hr>
              <h5 className="serif text-white mt-3 font-italic">Ethicly sourced BC nephrite jade.</h5>
            </div>
          </div>
        </BackgroundImage>

        <div className="black-background container-fluid">
          <div className="row justify-content-center p-5">
            
            <h1 className="col-12 col-lg-10 text-center text-md-left text-light serif d-inline-block px-0 px-sm-3">INTRODUCTION</h1>
            <div className="col-12 col-md-6 col-lg-5 px-0 px-md-3 pb-3 pb-md-5 text-center text-md-left">
              <h5 className="text-light font-italic serif mb-1 mt-4 pr-0 pr-md-5">
                Working in close partnership with jade producers in B.C. Canada to provide ethically sourced material for a global market.
              </h5>
            </div>
            <div className="col-12 px-0 col-md-6 col-lg-5">
              <p className="text-center text-md-left text-light sans-serif mb-1 mt-4 pl-0 pl-md-5">
                Take advantage of a suite of procurement, processing, 
                and in-field technical services designed to mitigate subjectivity and inefficiencies, 
                and encourage responsible resource development. 
                We have projects and partnerships in all segments of the nephrite value chain; 
                from exploration to grading, to lapidary services.
              </p>
            </div>
          </div>
        </div>

        {/* TODO: Add Different types of services to this block */}
        <div className="off-white-background container-fluid">
          <div className="row justify-content-between">
            <div className="col-12 col-md-7 py-5 py-md-0 px-5 text-center text-md-left align-self-center">
              <h1 className="text-dark serif d-none d-lg-inline-block mb-0 px-5">FIELD SERVICES</h1>
              <h2 className="text-dark serif d-inline-block d-lg-none mb-0 px-5 text-nowrap pt-2">FIELD<span className="d-none d-sm-inline-block">&nbsp;</span> <br className="d-inline-block d-sm-none"/>SERVICES</h2>
              <p className="text-dark sans-serif mt-4 px-0 px-sm-5">
                Unlock your next projects potential with a host of services specifically catered to nephrite placer and hard-rock resource development.
              </p>
            </div>
            <div className="col-12 col-md-5 d-block px-0">
              <Img fluid={this.props.data.gemTool.childImageSharp.fluid} alt="Vancouver Jade" />
            </div>
          </div>
        </div>

        <BackgroundImage className="p-5 container-fluid black-background" fluid={this.props.data.gemScenic.childImageSharp.fluid}>
          <div className="row p-0 p-sm-5 justify-content-center">
            <div className="col-12 px-0 col-md-8 col-lg-6 col-xl-5 text-center">
              <h1 className="text-white serif">OUR PROJECTS AND<br/>PARTNERSHIPS</h1>
              <p className="text-white sans-serif mt-4">
                We have several active projects in northern British Columbia at various development stages; from early field exploration to active quarrying.
              </p>
              <p className="text-white sans-serif mt-4">
                We've partnered with local stakeholders and traditional producers to utilize reclaimed placer and hard-rock nephrite. 
                Our unique model not only provides increased industry sustainability by unlocking capital, but also fosters best practices and encourages reclamation.
              </p>
            </div>
          </div>
        </BackgroundImage>

        <div className="off-white-background container-fluid">
          <div className="row justify-content-center p-5">
            <div className="col-md-8 col-lg-6 col-xl-5 px-0 text-center">
              <h2 className="text-dark serif d-inline-block mb-0">FOR MANUFACTURERS</h2>
              <hr className="col-1 horizontal-divider"></hr>
              <p className="text-dark sans-serif mt-4">
                Nephrite is our specialty. 
                We offer a variety of grading and lapidary services specifically developed for jewelry and luxury goods manufacturers and retailers. 
              </p>
              <p className="text-dark sans-serif mt-4">
                Our approach will allow you to incorporate jade in your products and leverage the stone's cultural significance, unrivaled demand, and timeless value.
              </p>
            </div>
          </div>
        </div>

        <div className="black-background container-fluid">
          <div className="row justify-content-around p-5">
            <div className="col-12 col-md-8 col-xl-5 text-center text-xl-left px-0 ml-0 ml-lg-5">
              { this.state.under576 ? 
                <h2 className="text-white serif d-inline-block">PROCESSING SERVICES</h2>
                :
                <h1 className="text-white serif d-inline-block">PROCESSING SERVICES</h1>
              }
              <div className="d-flex justify-content-center justify-content-xl-start my-4">
                <p className={`text-white sans-serif mr-4 mr-sm-5 py-2 product-specifications-tab ${this.state.tab === 'grading' ? 'active-tab' : ''}`} onClick={() => this.handleCategoryClick('grading')} >Grading</p>
                <p className={`text-white sans-serif mx-4 mx-sm-5 py-2 product-specifications-tab ${this.state.tab === 'slabbing' ? 'active-tab' : ''}`} onClick={() => this.handleCategoryClick('slabbing')} >Slabbing</p>
                <p className={`text-white sans-serif mx-4 mx-sm-5 py-2 product-specifications-tab ${this.state.tab === 'cnc' ? 'active-tab' : ''}`} onClick={() => this.handleCategoryClick('cnc')} >CNC</p>
              </div>
              <div className={this.state.tab === 'grading' ? 'd-block' : 'd-none'}>
                <p className="text-white sans-serif mt-4">
                  There are no widely accepted standards of grading nephrite gemstone. 
                  Current practices are highly subjective and are largely based on supply and demand trends. 
                </p>
                <p className="text-white sans-serif mt-4">
                  We offer procurement and grading expertise to ensure product consistency and quality. 
                  We work with clients and stakeholders to find and produce the perfect material for their unique products.
                </p>
              </div>
              <div className={this.state.tab === 'slabbing' ? 'd-block' : 'd-none'}>
                <p className="text-white sans-serif mt-4">
                  We offer pre-processing of thin section slabs to facilitate your jewelry processing needs as part of our lapidary services suite. 
                </p>
                <p className="text-white sans-serif mt-4">
                  Each slab is polished on one side for ease of material inspection and selection. 
                  We see this approach as the future of jade processing; 
                  mitigating the risk and subjectivity commonly associated with jade manufacturing.
                </p>
                <div className="d-flex justify-content-center justify-content-xl-start mt-5">
                  <div>
                    <h4 className="serif text-white">Thin Section</h4>
                    <p className="green-accent sans-serif">&#60; 3.0 mm<br/>(0.118 inches)</p>
                  </div>
                </div>
              </div>
              <div className={this.state.tab === 'cnc' ? 'd-block' : 'd-none'}>
                <p className="text-white sans-serif mt-4">
                  Jade's inherent toughness is equivalent to steel, 
                  allowing for exceptionally accurate water jet cutting of even the thinnest stone slabs. 
                </p>
                <p className="text-white sans-serif mt-4">
                  Vancouver Jade offers CNC water jet cutting of nephrite at a maximum thickness of 2.5cm (1.0 inch).
                </p>
              </div>
            </div>
            <div className={`col-11 col-sm-7 col-xl-4 align-self-center ${this.state.tab === 'grading' ? 'd-block' : 'd-none'}`}>
              <Img fluid={this.props.data.jadeGradeLight.childImageSharp.fluid} alt="Vancouver Jade" />
            </div>
            <div className={`col-11 col-sm-7 col-xl-4 align-self-center ${this.state.tab === 'slabbing' ? 'd-block' : 'd-none'}`}>
              <Img fluid={this.props.data.jadeClamp.childImageSharp.fluid} alt="Vancouver Jade" />
            </div>
            <div className={`col-11 col-sm-7 col-xl-4 align-self-center ${this.state.tab === 'cnc' ? 'd-block' : 'd-none'}`}>
              <Img fluid={this.props.data.jadeCut.childImageSharp.fluid} alt="Vancouver Jade" />
            </div>
          </div>
        </div>

        <div className="off-white-background container-fluid">
          <div className="row justify-content-center p-5">
            { this.state.under576 ? 
              <h2 className="text-center text-md-left text-dark serif d-inline-block">MATERIAL CHARACTERISTICS</h2>
              :
              <h1 className="text-center text-md-left text-dark serif d-inline-block text-nowrap">MATERIAL CHARACTERISTICS</h1>
            }
          </div>
          <div className="row justify-content-center pb-5">
            <div className="col-6 col-lg-2 border-right border-dark px-4 text-right text-lg-left">
              <h4 className="serif text-dark mb-4">Composition</h4>
              <p className="sans-serif text-dark small">Ca<sub>2</sub>(Mg,Fe)<sub>5</sub>Si<sub>8</sub>O<sub>22</sub>(OH)<sub>2</sub></p>
            </div>
            <div className="col-6 col-lg-2 hide-border-on-mobile pl-4">
              <h4 className="serif text-dark mb-4">Structure</h4>
              <p className="sans-serif text-dark small">Monoclinic,<br></br>Interlocking Crystals</p>
            </div>
            <div className="col-6 col-lg-2 border-right border-dark px-4 text-right text-lg-left">
              <h4 className="serif text-dark mb-4 ">Hardness</h4>
              <p className="sans-serif text-dark small">6 - 6.5 Mohs Scale</p>
            </div>
            <div className="col-6 col-lg-2 px-4">
              <h4 className="serif text-dark mb-4">Diaphaneity</h4>
              <p className="sans-serif text-dark small">Translucent to Opaque</p>
            </div>
          </div>
        </div>

        <Footer />

      </div>
    )
  }
};

export default Gemstones;
